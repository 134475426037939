
.notes
{
    width: 100%;
}

.notes__create
{
    cursor: pointer;
    color: var(--ui-primary-light);
}

.notes__create--disabled
{
    cursor: not-allowed;
    color: var(--ui-mid-tone);
}

.notes__action
{
    cursor: pointer;
}

    .notes__action svg
    {
        fill: var(--ui-text);
        fill-opacity: 0.6;
    }

.notes__action--disabled
{
    cursor: not-allowed;
}

    .notes__action--disabled svg
    {
        fill-opacity: 0.1;
    }
.note-scoll-height{
    max-height: 250px;
    overflow: auto;
}
.note-buttons-focus{
    box-shadow: none;
  }
  .note-buttons-focus:focus{
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  }