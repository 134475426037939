.space-card
{
    // margin-right: 25px;
    padding: 0px 25px 25px 0px;
    height: auto;

    .card
    {
        height: auto;
        .space-card__type
        {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-size: 0.625rem;
            padding-left: 19px;
            line-height: 22px;
            display: flex;
            align-items: center;
            letter-spacing: 0.69px;
            text-transform: uppercase;
            color: var(--ui-text-alternate);
        }

        .space-card__name
        {
            font-family: 'Source Sans Pro';
            font-style: normal;
            font-size: 1.125rem;
            margin-left: 19px;
            margin-top: 4px;
            line-height: 23px;
            display: flex;
            align-items: center;
            color: var(--ui-text);
            max-width: 282px;
        }
    }

    .space-card__primary-info-row
    {
        width: 290px;
        height: 119px;
        position: absolute;
        z-index: 9;
        background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
        opacity: 0.8;
        padding: 0 14px 0 14px;

        .space-card__primary-info
        {
            margin: 0 0 2px 0;
            padding-left: 0;
            padding-right: 0;

            .space-card__icon
            {
                img
                {
                    filter: invert(143%) sepia(158%) saturate(161%) hue-rotate(570deg) brightness(100%) contrast(1000%);
                    height: 18px;
                    width: 18px;
                    padding: 0 12px 0 5px;
                }
            }

            .space-card__text
            {
                display: flex;
                overflow: hidden;
                height: 36px;
                white-space: normal;
            }
        }
    }

    .card-img-top
    {
        width: 318px;
        object-fit: cover;
        height: 119px;
        z-index: 2;
        border-top-right-radius: 7px;
        border-top-left-radius: 7px;
    }

    .border
    {
        border: 1px solid var(--ui-mid-tone) !important;
        border-radius: 9px;
    }

    .card-selected {
        border: 3px solid var(--ui-primary-light) !important;
        box-shadow: 0px 4px 4px 0px #00000040;
    }
    .focused {
       
        outline: none; 
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
        background-color: #e0e0e0;
    }
}
