
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-content{
  /* padding-top:38px;
  padding-right:55px;
  padding-left:55px; */
    /* height: calc(100% - 202px); */
    position: relative;
    transition: margin-left 300ms;
    /* overflow-x: hidden; */
    /* overflow-y: hidden; */
}
.main-page-title{
  height: 50px;
}
.main-button-content{
  padding-top: 15px;
  /* padding-right: 55px;
  padding-left: 55px; */
}
.main-button-container{
  padding-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}