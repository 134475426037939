.bookingSummary-container
{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.bookingSummary-info
{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}