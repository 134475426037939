
.chart-download-button
{
    position: relative;
}

.chart-download-button__download-button img
{
    width: 32px;
}

.chart-download-button__overlay
{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.chart-download-button__options
{
    position: absolute;
    top: 39px;
    left: 0;
    background-color: var(--ui-background-alternate);
    border: 1px solid #0000003b;
    z-index: 1;
    box-shadow: 5px 5px 5px #0003;
}
