/** FORM PANEL */
.rightPanel-main-content-form {
  padding: 25px;
  overflow: auto;
}

.form-panel {
  background-color: var(--ui-background-alternate);
  position: relative;
  border-radius: 10px;
  padding: 16px;
  // margin-top: 16px;
  // margin-bottom: 16px;
  max-height: calc(100vh - 216px);
    overflow: hidden;
    overflow-y: auto;
  // overflow: auto;
  // height: calc(100vh - 300px);
}
.form-panel::-webkit-scrollbar {
  display: none;
}
.form-panel-info {
  margin-bottom: 8px;
}

.form-panel-heading {
  position: relative;
}

.form-panel-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: var(--ui-text);
}

.form-panel-icon img {
  height: 26px;
  width: 26px;
  margin-bottom: 5px;
}

.form-panel-breadcrumbs {
  position: relative;
  display: inline;
  left: 16px;
  color: var(--ui-text);
}


.form-panel-header {
  display: inline-block;
  position: relative;
  margin-left: 12px;
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 1.625rem;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--ui-text);
}

.form-panel-description {
  font-family: Source Sans Pro;
  font-style: normal;
  font-size: 0.75rem;
  color: var(--ui-text-alternate);
  height: 31px;
}

.rightPanel-main-content-form>.form-panel:nth-child(1) {
  margin-top: 0px;
}

.rightPanel-main-content-form>.form-panel:nth-last-child(1) {
  margin-bottom: 0px;
}

.form-panel-form {
  position: relative;
  margin: 0px 7px 0px 11px;
}

.form-input-text {
  font-size: 0.875rem !important;
  font-family:  Source Sans Pro;
}


.form-panel-card {
  padding: 10px 40px 10px 40px;
  margin-top: 35px;
  margin-bottom: 25px;
  border-radius: 4px;
  box-shadow: 0px 2px 7px rgba(38, 50, 56, 0.1);
}

.form-input-box-textarea {
  height: 90px !important;
}

.form-input-box {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;

  .form-input-box-label {
    width: 256px;
    font-size: 0.875rem;
    color: var(--ui-text);
    text-overflow: ellipsis;
    font-family: Source Sans Pro;
  }

  .form-input-box-Left{
    width: 135px;
    font-size: 1rem;
    color: var(--ui-text);
    text-overflow: ellipsis;
  }

  .form-input-box-required-asterisk {
    width: 37px;
    font-size: 0.875rem;
    color: var(--ui-error);
    display: flex;
    justify-content: center;
    justify-items: center;
  }

  .form-input-box-input {
    border: none;
    padding: 0rem 1.5rem;
    height: 40px;
    display: flex;
    flex-grow: 1;
    font-size: 0.875rem;
    font-family:  Source Sans Pro;
    color: var(--ui-text);
    border: 1px solid transparent;
    background-color: transparent;
    font-weight: bold;

    input {
      font-size: 0.875rem !important;
      font-family: Source Sans Pro;
    }
  }

  .form-input-box-input--textarea {
    height: 55px;
    
  }


  .form-input-box-input:read-only:focus {
    border: 1px solid transparent;
    background-color: var(--ui-mid-tone);
    border-radius: 5px;
  }

  .form-input-checkbox {
    margin-left: 24px;
    cursor: pointer;
    height: 32px;
    width: 32px;
    font-size: 0.875rem;
    border-radius: 5px;
    border: 0.5px solid var(--ui-text-alternate);
  }


  .form-input-checkbox:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    font-size: 0.875rem;
    background: var(--ui-mid-tone);
  }

  .form-input-checkbox:checked:disabled {
    -webkit-appearance: auto;
  }

  .form-input-checkbox:checked:disabled::after {
    -webkit-appearance: auto;
    content: "";
    border-radius: 5px;
    border: 0.5px solid var(--ui-text-alternate);
    padding-left: 30px;
    padding-right: 0px;
    padding-top: 0px;
    padding-bottom: 30px
  }

  .form-input-switch {
    margin-left: 19px;
  }


  .form-input-attendees {
    width: auto;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  .form-input-attendees .border {
    border: none;
    margin-right: 12px;
    padding: 0rem 1.5rem;
    height: 40px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    font-size: 0.875rem;
    font-family: Source Sans Pro;
    color: var(--ui-text);
    border-radius: 5px;
  }

  .form-input-attendees .border:focus {
    color: var(--ui-text);
    border: 1px solid var(--ui-secondary);
  }

  .form-input-attendees button {
    margin-left: 23px;
    height: 40px;
    width: auto;
  }

  .form-input-box-autocomplete {
    border: 1px solid transparent;
    margin-right: 0px;
    flex-grow: 1;

    .MuiAutocomplete-input {
      font-size: 0.875rem !important;
      font-family:  Source Sans Pro;
      color: var(--ui-text);
    }
  }


  .form-input-box-autocomplete:focus-within {
    outline: none !important;
    border: 1px solid var(--ui-text);
    border-radius: 5px;
  }

  .form-input-box-autocomplete[data-disabled="true"] {
    border: none;
  }

  .form-input-box-autocomplete fieldset {
    border: none;
  }

  .form-checkbox-geekmark {
    position: absolute;
    margin-top: -4px !important;
    height: 32px;
    width: 32px;
    background-color: var(--ui-background-alternate);
    border-radius: 5px;
    margin-top: 7px;
    border: 0.5px solid var(--ui-text-alternate);
  }

  .newCheckBox .checkBoxHide:disabled~.form-checkbox-geekmark {
    background: var(--ui-mid-tone);
  }

  .form-input-box-input:focus {
    outline: none !important;
    border: 1px solid var(--ui-text);
    border-radius: 5px;
    // box-shadow: 0 0 10px #719ECE;
  }

    .visit-filter-label {
      font-size: 1rem !important;
      width: 110px !important;
      color: var(--ui-text) !important;
    }
  
    .visit-filter-datepicker {
      border-radius: 5px !important;
    }
  
    .visit-filter-selectbox {
      height: 40px !important;
      border-radius: 5px !important;
    }


  .react-datepicker-wrapper {
    display: flex;
    flex-grow: 1;
    width: auto;
  }

  .form-input-box-datetime-picker {
    border: 1px solid transparent;
    padding: 0rem 1.5rem;
    height: 40px;
    font-size: 0.875rem;
    font-family:  Source Sans Pro;
    color: var(--ui-text);
    background-color: var(--ui-background-alternate);
    font-weight: bold;
  }

  .form-input-box-datetime-picker:focus-visible {
    outline: none;
    border: 1px solid var(--ui-text);
    border-radius: 5px;
  }
}
.jodit-workplace{
  height: 350px !important;
}

.form-input-box--jodit {
  height: auto;
  display: flex;
  align-items: center;
  width: 100%;


  .jodit-react-container {
    display: flex;
    flex-grow: 1;
  }

  .jodit-container {
    width: 100% !important;
  }

  .jodit-wysiwyg {
    height: 350px !important;
  }
}

.jodit-toolbar__box {
  position: relative !important;
}

.title-secondary {
  text-transform: capitalize;
  color: var(--ui-text);
  margin-bottom: 25px;
  font-size: 1.625rem;
}

.switch-field {
  display: flex;
  overflow: hidden;
  background-color: var(--ui-mid-tone);
  width: 176px;
  border-radius: 20.5px;
  height: 41px;
  margin-top: 7px;
}

.switch-field input {
  position: absolute !important;
  height: 1px;
  width: 1px;
  border: 0;
  overflow: hidden;
  visibility: collapse;
}

.switch-field label {
  color: var(--ui-text);
  font-size: 0.875rem;
  text-align: center;
  padding: 16px;
  margin-right: -1px;
  line-height: 10px;
  cursor: pointer;
}

.switch-field input:checked+label {
  background-color: var(--ui-primary);
  box-shadow: none;
  border-radius: 60px;
  width: 90px;
  color: var(--ui-light-text);
  transition: all 0.5s;
}

.switch-field label:first-of-type {
  border-radius: 4px 0 0 4px;
  background-color: transparent;
  width: 90px;
  transition: all 0.5s;
}

.detailed-right {
  justify-content: space-between;
  width: 100%;
  font-size: 1rem;
  color: var(--ui-text);
  text-transform: uppercase;
  margin-bottom: 25px;
}

.input-controls {
  display: flex;
  flex-direction: column;
}

.input-controls label {
  color: var(--ui-secondary);
  font-size: 0.875rem;
  font-family:  Source Sans Pro;
  height: 37px;
  line-height: 37px;
  width: 256px;
  // left: 27px;
  text-overflow: ellipsis;
}

.input-box,
.form-control {
  height: 40px;
  display: block;
  border-bottom: 1px solid;
  font-size: 0.875rem;
  color: var(--ui-text) !important;
  // background: var(--ui-background-alternate) !important;
  border-color: (--scroll-track ) !important;
  padding: 0rem 1.5rem;
  border-radius: 4px;
  // margin: 0rem 0rem 1.0rem 0rem;
  font-family: Source Sans Pro !important;
  margin-right: 23px;
  font-weight:bold !important;
}

.input-box:read-only {
  background-color: var(--ui-mid-tone);
}

.input-check-box {
  height: 32px;
  width: 32px;
  display: block;
  border: 2px solid;
  font-size: 1rem;
  color: var(--ui-text);
  background: var(--ui-background-alternate);
  border-color: var(--ui-mid-tone) !important;
  border-radius: 5px;
  // margin: 10px 0px 0px 0px;
}

.input-check-box-label {
  height: 40px;
  width: 32px;
  display: block;
  position: relative;
  padding-left: 35px;
  // margin-bottom: 25px;
  cursor: pointer;
  font-size: 0.875rem;
  color: var(--ui-text);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-check-box-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  background-color: var(--ui-background-alternate);
  border: 2px solid;
  border-color: var(--ui-mid-tone);
  border-radius: 5px;
  margin-top: 7px;
}

.input-check-box-label:hover input~.checkmark {
  background-color: var(--scroll-track);
}

.input-check-box-label input:checked~.checkmark {
  background-color: var(--ui-primary);
  border: 2px solid;
  border-color: var(--ui-primary);
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.input-check-box-label input:checked~.checkmark:after {
  display: block;
}

.input-check-box-label .checkmark:after {
  left: 8px;
  top: 1px;
  width: 8px;
  height: 18px;
  border: solid var(--ui-background-alternate);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.check-label {
  color: var(--ui-text);
  height: 37px;
  line-height: 37px;
  font-family: uiParagraphSemibold;
}

.label {
  color: var(--ui-secondary);
  font-size: 0.875rem;
  font-family:  Source Sans Pro;
  height: 37px;
  line-height: 37px;
  width: 256px;
  text-overflow: ellipsis;
}

.custom-select-wrapper {
  position: relative;
  user-select: none;
  color: var(--ui-text);
  text-overflow: ellipsis;
  font-family: Source Sans Pro !important;
  // font-size: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 23px;

  .custom-select {
    position: relative;
    display: flex;
    flex-direction: column;
    border-width: 0;
    border: 1px solid;
    border-color: var(--ui-secondary);
    border-radius: 4px;
    margin-bottom: 16px;
    font-family: Source Sans Pro !important;
    font-size: 1rem;

    .custom-select__trigger {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px;
      color: var(--ui-text);
      height: 40px;
      line-height: 54px;
      background: var(--ui-background-alternate);
      cursor: pointer;
      border-radius: 4px;
      font-family: Source Sans Pro !important;
      font-size: 0.875rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow-y: hidden;

      .arrow {
        position: relative;
        font-size: 1.5em;
        width: 15px;
      }
    }

    .custom-options {
      position: absolute;
      display: block;
      max-height: 200px;
      overflow: auto;
      top: 100%;
      left: -1px;
      right: 0;
      width: 100%;
      border: 1px solid;
      border-radius: 4px;
      border-color: var(--ui-text);
      background: var(--ui-background-alternate);
      transition: all 0.5s;
      font-size: 1rem;
      z-index: 9999;

      .custom-option {
        position: relative;
        font-family: Source Sans Pro !important;
        display: block;
        padding: 0 25px; //0 25px
        color: var(--ui-text-alternate);
        line-height: 54px;
        border-top: 1px solid var(--ui-line-brake);
        height: 54px;
        cursor: pointer;
        transition: all 0.5s;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 1rem;
      }

      .custom-option:hover {
        cursor: pointer;
        color: var(--ui-light-text);
        background-color: var(--ui-primary);
      }
    }
  }

  [data-disabled="true"] .custom-select__trigger {
    background: var(--ui-mid-tone);
  }
}

.inprogress-text {
  color: var(--ui-warn);
}

.progress-text {
  color: var(--ui-text-alternate);
}

.textAreaRoles {
  height: calc(100vh - 545px);
  width: 100%;
  border-radius: 4px;
  resize: none;
  font-size: 1rem;
}

.textAreaUserDetails {
  height: calc(100vh - 470px);
  width: 100%;
  border-radius: 4px;
  resize: none;
  font-size: 1rem;
  overflow-y: scroll;
}

.div-flex {
  display: flex;
}

[data-disabled="true"] .input-controls__disable {
  background: var(--ui-mid-tone);
  padding: 1rem;
}


.date-input-box {
  height: 2.3rem;
  width: 5rem !important;
  display: block;
  border-bottom: 1px solid;
  font-size: 1rem;
  color: var(--ui-text) !important;
  border-color: var(--ui-text) !important;
  padding: 0rem 1.5rem;
  border-radius: 4px;
  margin: 0rem 0rem 1.0rem 0rem;
  font-family: Source Sans Pro !important;
  height: 36px !important;
}

.date-input-box:read-only {
  background-color: var(--ui-mid-tone);
}

.dropdown-selector {
  font-family: Source Sans Pro;
  font-size: 1rem;
}

.auto-text {
  margin-right: 23px;
  height: 40px;
  padding: 0px !important;
}

.auto-text div>div {
  padding-top: 3px !important;
}

.auto-text div>div>input {
  font-size: 0.875rem;
  font-family: Source Sans Pro !important;
  margin-left: 12px !important;
  font-weight: bold !important;
  color: var(--ui-text);
}

.auto-text div>div>input:read-only {
  padding-top: 8px !important;
  padding-bottom: 10px !important;
}

.auto-text div>div>div>button>svg {
  margin-top: -10px !important;
  color: var(--ui-text);
}

.required {
  color: var(--ui-error);
}

.toggle.ios,
.toggle-on.ios,
.toggle-off.ios {
  border-radius: 20rem;
}

.toggle.ios .toggle-handle {
  border-radius: 20rem;
}


.select-label {
  width: 256px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  color: var(--ui-secondary);
}

.custom-form-control {
  border: 1px solid transparent;
  width: 100%;
  font-size: 0.875rem;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 5px;
  background-color: var(--ui-background-alternate);
  color: var(--ui-text);
  font-family: Source Sans Pro !important;
  font-weight: bold;
}

.custom-form-control:focus {
  border: 1px solid #ced4da;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: block;
  -moz-appearance: block;
  text-overflow: '';
}

.form-input-select-box {
  border: 1px solid transparent;
  display: flex;
  flex-grow: 1;
  height: 40px;
  width: auto;
}

.filter-modal-label {
  font-size: 1.125rem;
}

.fileter-modal-input{
  height: 40px;
  width: 100%;
  border-radius: 5px;
}
.fileter-space-modal-input{
  height: 20px !important;
}
.fileter-space-modal-input:focus-visible{
  outline: none !important;
}

.fileter-modal-input:focus-visible{
  outline: none !important;
}
.notification-height{
  height: calc(100vh - 198px) !important;
}
.notification-height-submenu{
  height: calc(100vh - 240px) !important;
}
@media screen and (max-width: 900px) {
  .notificn-priority-textfield{
    margin-top: 50px !important;
}
}

.border{
  border-radius: 4px;
  height:40px;
  margin: 6px 0px;
}

