.search-results-height{
    flex: 1;
    max-height: calc(100vh  - 228px);
    overflow: auto;
    .space-card-container
    {
        padding-top: 25px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}