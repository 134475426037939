.space-box-cont {
  display: flex;
  justify-content: space-between;
  // height: 40px;

  .search-results-title
  {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-size: 1.625rem;
    color: var(--ui-text);
  }

  .left-space-box-cont {
    display: flex;
    justify-content: start;
    height: 18px 18px;
    color: var(--ui-secondary);
  }
  .space-box {
    padding: 10px 20px;
    border: 1px solid;
    border-color: var(--ui-text-alternate);
    color: var(--ui-text);
    background-color: var(--ui-background-alternate);
    margin-right: 12px;
    text-align: center;
    height: 40px;
    line-height: 18px;
    cursor: pointer;
    border-radius: 7px;
    box-sizing: border-box;
    margin-bottom: 5px;
    white-space: nowrap;
  }

  .space-icon img {
    height: 18px;
    width: 18px;
    color: --ui-secondary;
    margin-top: 3px;
  }

  .space-text {
    font-family: Source Sans Pro;
    font-style: normal;
    color: var(--ui-text);
    font-size: 0.875rem;
    margin-left: 14px;
  }
}
.flex-row-bredcrumb{
  display: flex;
  flex-wrap: wrap;
  width: calc(100vw - 450px);
  justify-content: flex-start !important;
}
.ibss-breadcrumb .MuiChip-filled{
  background: var(--ui-background);
  border: 2px solid var(--ui-text-alternate);
  border-radius: 5px;
  padding:10px 20px;
  margin-bottom: 15px;
  height: 38px;
}
.card-result{
  padding: 10px;
  background-color: var(--ui-background-alternate);
  border-radius: 8px;
  box-sizing: border-box;
  flex: auto;
}
.search-result{
  box-shadow: 0px 2px 7px rgba(38, 50, 56, 0.1);
  border-radius: 10px;
  background-color: var(--ui-background-alternate);
  padding: 20px 21px;
  flex: auto;
  box-sizing: border-box;
}
.kpi-analytics-bg{
    width: 50px;
    height: 50px;
    background: var(--ui-text-alternate);
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:var(--ui-background-alternate);
  }
//  search results
.search-results-cont
{
    flex: 1;
    height: calc(100vh - 218px);
}

    .search-results-cont.search-results-cont--alert
    {
        overflow-y: visible;
    }

    .search-results-cont.search-results-cont--map
    {
        overflow-y: hidden;
    }

    .search-results-cont.search-results-cont--list
    {
        overflow-y: auto;
    }

.spaceIcon {
  height: 18px;
  width: 18px;
  // padding-top: 19px;
  padding-left: 12px;
  padding-right: 12px;
  color: var(--ui-background-alternate);
}

.text-white{
  color: var(--ui-light-text) !important;
}
.space-text-item {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 0.875rem;
  line-height: 18px;
  display: flex;
  align-items: center;
  color:var(--ui-text-alternate);
  // padding: 14px 0px 0px 0px;

}

.capacity-box {
  width: 57px !important;
  height: 25PX !important;
  border-radius: 16px;
  margin-left: 15px;
  background: var(--ui-background)
}

.capacity-box img {
  height: 13px;
  width: 18px;
  padding-top: 19px;
  padding-left: 6px;
  padding-right: 12px;
  color: var(--ui-text-alternate);

}


.capacity-number {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 0.875rem;
  line-height: 18px;
  // display: flex;
  align-items: center;
  text-align: right;
  padding-right: 6px;
  color: var(--ui-text-alternate);

}

.capacityBox {
  width: 57px;
  height: 25px;
  border-radius: 50px;
  margin-left: 10px;
  overflow: auto;
  background: var(--ui-background)
}

.capacityBox img {
  height: 13px;
  width: 18px;
  color: var(--ui-text-alternate);

}

.page-container .rightPanel {
  overflow: auto;
}




/* FlexMySearchFC starts here*/
.flex-search-filter-criteria {
  top: 60px !important;
  min-width: 500px;
  max-width: 600px;
  width: 600px !important;
  overflow: auto;
}

.flexMySearch-filter-criteria-header .flexMySearch-filter-criteria-title {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 1.625rem;
  line-height: 33px;
  color: var(--ui-text);
  margin: 20px;
}

.flexMySearch-filter-criteria-header {
  margin: 44px 24px 35px 24px;
}

.flexMySearch-filter-criteria-img {
  width: 31px;
  // height: 31px;
}

.flexMySearch-filter-criteria-close {
  font-size: 2.375rem;
  float: right;
  cursor: pointer;
  color: var(--ui-text);}

.flexMySearch-filter-criteria-content {
  padding-left: 24px;
}

.flexMySearch-filter-criteria-firstLabel {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
  padding-top: 14px;
  align-items: center;
  color: var(--ui-text-alternate);
}

.flexMySearch-filter-criteria-select-label {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 2.375;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: var(--ui-text);
  padding-left: 3px;
  padding-top: 20px;
}

.flexMySearch-filter-criteria-select-selectBox {
  padding-top: 20px;
  padding-right: 18px;
}

.flexMySearch-filter-criteria-border {
  border-top: 1px solid var(--ui-mid-tone);
  margin-top: 10px;
  margin-bottom: 10px;
}

.flexMySearch-filter-criteria-select {
  border: 1px solid;
}

.flexMySearch-filter-criteria {
  padding-bottom: 100px;
}

// #fms_fc_bldng {
//   padding: 8px;
// }

.fms-fc-placeholder {
  color: var(--ui-text-alternate) !important;
}

.select-box-custom {
  border: 1px solid var(--ui-text) !important;
  border-radius: 5px !important;
}

.side {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 7px;
}

.form-panel-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // padding: 15px 30px 15px 30px;
  gap: 17px;
  background-color: var(--ui-background-alternate);
  border-radius: 7px;
  // margin-top: 16px;
  margin-bottom: 16px;
  box-shadow: 0px 2px 7px rgba(38, 50, 56, 0.1);
  top: 141px !important;
  bottom: 38.33%;
  height: auto;
  width: 400px;
  left: 271px;
  box-sizing: border-box;
}
.gap-0{
  gap: 0px !important;
}

.form-panel-right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // padding: 40px 30px 34px 30px;
    gap: 17px;
    margin-left: 20px;
    margin-bottom: 20px;
    // position: absolute;
    // width: 384px;
    height: fit-content;
    left: 797px;
    // top: 141px;
    background-color: var(--ui-background-alternate);
    box-shadow: 0px 2px 7px rgba(38, 50, 56, 0.1);
    border-radius: 7px;
    top: 141px !important;
}

.spacetype {
  width: 304.29px;
  height: 22px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.69px;
  text-transform: uppercase;
  color: var(--ui-text-alternate);
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.spacetext {
  width: 304.29px;
  // height: 47px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 2.813rem;
  line-height: 47.47px;
  display: flex;
  align-items: center;
  color: var(--ui-text);
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.cardimg {
  width: 100%;
  height: 246px;
  border-radius: 8px;
  overflow:hidden;
}
.cardimg img{
  width: 100%;
  object-fit: cover;
  height: 100%;
}


hr {
  border: 1px solid var(--ui-mid-tone);

  hr:active {
    border: 1px solid var(--ui-primary);
  }
}

.inputboxmodel {
  width: 323px;
  height: 40px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 0.875rem;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: var(--ui-text) !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  font-weight: bold !important;
  background-color: transparent;
}

.form-input-box-label-date {
  width: 52px;
  height: 18px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 0.875rem;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: var(--ui-text-alternate);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.inputboxmodel-date {
  height: 47px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 2.813rem;
  line-height: 47px;
  display: flex;
  align-items: center;
  color: var(--ui-text);
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;
  background-color: transparent;
}

.alldaylabel {
  // width: 40px;
  height: 40px !important;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 0.875rem;
  line-height: 18px;
  color: var(--ui-text-alternate) !important;
}

.booking-button {
  width: 323px !important;
  height: 50px !important;
  margin-left: 25px;

}

.attendes {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dateonly {
  width: 110px !important;
  height: 18px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 0.875rem;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: var(--ui-secondary);
}

.timeDate {
  height: 20px;
  // width: 75px !important;
  border: none;
  color: var(--ui-text);
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-size: 0.875rem;
  line-height: 18px;
  display: flex;
  align-items: center;
  // padding-top: 19px;
  font-weight: bold;
  background-color: transparent;
}
.space-icon-item{
  display:flex;
}
.icon-text-inline {
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.img-align {
  margin-left: -15px;
}

.icon-align {
  margin-left: -25px;
}

.align-icons-text {
  display: flex;
  align-items: center;
}
.align-icons-text-end{
  display: flex;
  align-items: end;
  margin:15px 10px;
}
.circle-labels {
  display: flex;
  justify-content: center;
  height: 36px;
  width: 38px;
  border-radius: 50px;
  color: var(--ui-light-text);
  line-height: 36px;
  margin-left: 10px;
  font-size: 1rem;
  background-color: var(--ui-secondary)!important;
  border: 1px solid var(--ui-text-alternate );
}

.overlap {
  margin-left: -6px;
  margin-top: 0px;
  margin-bottom:0px;
}

.align-right {
  margin-left: 318px;
}
.btn-back{
  display: inline-block;
  height: 40px;
  width: 100px;
  padding: 0.35rem 0.75rem;
  line-height: 1.2;
  border-radius: 8px;
  border: 1px solid var(--ui-text);
  font-size: 1rem;
  font-weight: 600;
  font-family: Source Sans Pro;
  color: var(--ui-text-alternate);
  background-color: transparent;
  cursor: pointer;
}
.btn-popup-tab{
    border: none;
    height: 40px;
    font-size: 0.875rem;
    line-height: 18px;
    text-align: center;
    color: var(--ui-text);
    width: 170px!important;
    text-decoration: none;
    white-space: nowrap;
    background-color: var(--ui-mid-tone);
    cursor: pointer;

}
.btn-popup-tab:hover,.btn-popup-tab:focus{
  background: var(--ui-background);
  color:var(--ui-primary);
}

.btn-popup-tab-active{
    border: none;
    height: 40px;
    font-size: 0.875rem;
    line-height: 18px;
    text-align: center;
    width: 170px!important;
    text-decoration: none;
    white-space: nowrap;
    background: var(--ui-background);
    color:var(--ui-primary);
}

.No-Results {
  text-align: center;
  margin-top: 10%;
}

.back-round{
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: var(--ui-background);
  justify-content: center;
  align-items: center;
  display: flex;
}

.bookingTitle{
  font-family: 'Source Sans Pro';
  font-size: 1.625rem;
  color: var(--ui-text);
}
.search-result-vh{
  height: calc(100vh - 174px);
  overflow-y: auto;
}

.cancelBooking{
  display         : inline-block;
  height          : 50px;
  width           : 209px;
  border-radius   : 8px;
  font-family     : Source Sans Pro;
  border          : none;
  font-size       : 1rem;
  background-color: var(--ui-error);
  color           : var(--ui-light-text);
  cursor          : pointer;
  padding         : 0.35rem 0.75rem;
  line-height     : 1.2;
}

.cancelBooking:disabled{
  background-color: var(--ui-text-alternate);
  opacity: 0.65;
}

.op-5{
  opacity: 0.5;
}

.cp{
  cursor: pointer;
}

.cna{
  cursor: not-allowed;
}
@media (max-width:1078px){
  .card-result{
    flex: inherit;
  }
}
@media (min-width:1430px){
  .search-results-cont
  {
      height: calc(100vh - 418px);
  }
}