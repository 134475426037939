.oneLensScheduleView .e-header-cells.e-disable-dates
{
    display: none;
}

.oneLensScheduleView .e-schedule-table
{
    tbody 
    {
        tr.e-header-row:nth-child(2)
        {
            display: none;
        }
    }
}