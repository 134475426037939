.space-info-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: none !important;
}

.space-info-content {
    padding: 16px;

    h3 {
        font-weight: bold;
        font-size: 1.3em;
    }

    p {
        font-size: 0.875rem; 
        line-height: 1.5; 
    }
}

.ibss-dialog-backdrop {
    box-shadow: none !important;
}
