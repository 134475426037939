.floorplan
{
    position: relative;
    height: calc(100% - 2px);
    min-height: 200px;
    min-width: 200px;
    border: solid var(--ui-text-alternate) 1px;
    border-radius: 7px;
    background-color: var(--ui-background-alternate);

    .floorplan-buttons
    {
        position: absolute;
        z-index: 1;
        top: 5px;
        left: 5px;

        .floorplan-pan
        {
            position: absolute;
            top: 0;
            left: 0;
            width: 60px;
            height: 60px;
            border: solid var(--ui-text-alternate) 1px;
            border-radius: 30px;
            background-color: var(--ui-background);
        }

        .floorplan-north
        {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 20px;
            padding: 0 4px 4px 7px;
            /*background-color: blue;*/
        }

        .floorplan-north-east
        {
            position: absolute;
            cursor: pointer;
            top: 1px;
            left: 40px;
            width: 19px;
            height: 19px;
            border-radius: 0 19px 0 0;
            /*background-color: red;*/
        }

        .floorplan-east
        {
            position: absolute;
            cursor: pointer;
            top: 20px;
            left: 40px;
            padding: 3px 4px 1px 11px;
            /*background-color: blue;*/
        }

        .floorplan-south-east
        {
            position: absolute;
            cursor: pointer;
            top: 40px;
            left: 40px;
            width: 19px;
            height: 19px;
            border-radius: 0 0 19px 0;
            /*background-color: red;*/
        }

        .floorplan-south
        {
            position: absolute;
            cursor: pointer;
            top: 40px;
            left: 20px;
            padding: 5px 4px 0px 7px;
            /*background-color: blue;*/
        }

        .floorplan-south-west
        {
            position: absolute;
            cursor: pointer;
            top: 1px;
            left: 1px;
            width: 19px;
            height: 19px;
            border-radius: 19px 0 0 0;
            /*background-color: red;*/
        }

        .floorplan-west
        {
            position: absolute;
            cursor: pointer;
            top: 20px;
            left: 0;
            padding: 3px 9px 1px 6px;
            /*background-color: blue;*/
        }

        .floorplan-north-west
        {
            position: absolute;
            cursor: pointer;
            top: 40px;
            left: 1px;
            width: 19px;
            height: 19px;
            border-radius: 0 0 0 19px;
            /*background-color: red;*/
        }

        .floorplan-centre
        {
            position: absolute;
            cursor: pointer;
            top: 20px;
            left: 20px;
            padding: 3px 3px 1px 4px;
            /*background-color: red;*/
        }

        .floorplan-zoom-in
        {
            position: absolute;
            border: solid var(--ui-text-alternate) 1px;
            color: var(--ui-text-alternate);
            background-color: var(--ui-background);
            cursor: pointer;
            top: 7px;
            left: 68px;
            padding: 3px 5px 3px 5px;
            border-radius: 10px 10px 0 0;
        }

        .floorplan-zoom-out
        {
            position: absolute;
            border: solid var(--ui-text-alternate) 1px;
            color: var(--ui-text-alternate);
            background-color: var(--ui-background);
            cursor: pointer;
            top: 30px;
            left: 68px;
            padding: 3px 5px 3px 5px;
            border-radius: 0 0 10px 10px;
        }
    }

    .react-transform-wrapper
    {
        width: 100%;
        height: 100%;
        border-radius: 7px;

        .react-transform-component
        {
            width: 100%;
            height: 100%;

            .floorplan-container
            {
                width: 100%;
                height: 100%;

                svg
                {
                    width: 100%;
                    height: 100%;

                    *[data-isspace="true"]
                    {
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.floorplan-modal
{
    .modal-dialog
    {
        max-width: 318px !important;
        top: 50%;
        margin-top: -152px;

        .modal-content
        {
            width: 318px !important;
            height: 305px;

            .modal-body
            {
                padding: 0 !important;

                .space-card
                {
                    padding: 0;

                    .card
                    {
                        cursor: initial;
                    }
                }

                .floorplan-modal-button
                {
                    margin-left: 19px;
                    width: 280px;
                }
            }
        }
    }
}

.floorplan-hasBookingData
{
    .modal-dialog
    {
        .modal-content
        {
            height: 340px;
        }
    }
}

.floorplan-noBookingData
{
    .modal-dialog
    {
        .modal-content
        {
            height: 400px;
        }
    }
}