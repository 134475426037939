

.otp-group {
  display: flex;
  width: 100%;
  max-width: 160px;
  column-gap: 10px;
}

.otp-input {
  width: 100%;
  height: 30px;
  border: none;
  background-color: var(--ui-mid-tone);
  border-radius: 5px;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
}
.otp-input:focus-visible{
  border:none;
  outline: none;
}