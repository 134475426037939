.menu-items-scroll{
    height: 100vh;
    overflow: auto;
  }
.task-defination-popup{
    width:94%;
    padding:20px;
}
@media (min-width: 1468) {
    .menu-items-scroll{
      height: calc(100vh - 150px)!important;
      overflow: auto;
    }
}
@media (min-width: 1469px) {
    .menu-items-scroll{
      height: calc(100vh - 65px)!important;
      overflow: auto;
    }
}
@media (min-width: 1800px) {
    .menu-items-scroll{
      height: calc(100vh - 198px)!important;
      overflow: auto;
    }
}
@media (min-width: 1900px) {
    .menu-items-scroll{
      height: calc(100vh - 294px)!important;
      overflow: auto;
    }
}
