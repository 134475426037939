.clickable-data-grid-row{
    .MuiDataGrid-cellContent{
        cursor: pointer;
    }
    .MuiDataGrid-cell:focus{
        outline: none !important;
    }
    .MuiDataGrid-cell--withRenderer{
        cursor: pointer;
    }
}