.iconics-alert
{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    text-align: center;

    .iconics-alert-title
    {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-size: 1.625rem;
        line-height: 33px;
        display: flex;
        align-items: center;
        text-align: center;
        margin-bottom: 10px;
        color: var(--ui-text);
    }

    .iconics-alert-text
    {
        font-family: 'Source Sans Pro';
        font-style: normal;
        font-size: 1rem;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: center;
        color: var(--ui-text-alternate);
    }
}
