.markdown-viewer
{
}

    .markdown-viewer .markdown-editor__editor
    {
        max-height: 150px;
        border: none;
        padding: 0;
        overflow-y: auto;
    }

        .markdown-viewer .markdown-editor__editor:hover
        {
            border: none;
        }

        .markdown-viewer .markdown-editor__editor a
        {
            cursor: pointer;
        }
