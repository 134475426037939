.infinite-scroller
{
    text-align: center;

    .spinner-border
    {
        width: 1rem;
        height: 1rem;
        color: silver !important;
    }
}
