.page-container
{
    .rightPanel
    {
        .rightPanel-main-content{
            
            .row{

                .react-transform-wrapper
                {
                    width: 100%;
                    height: 100%;
                    border-radius: 7px;
            
                    .react-transform-component
                    {
                        width: 100%;
                        height: 100%;
            
                        .floorplan-container
                        {
                            width: 100%;
                            height: 100%;
            
                            svg
                            {
                                width: 100%;
                                height: 100%;
            
                                *[data-fillcolour="ui-success"],
                                *[data-strokecolour="ui-success"]
                                {
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    
}


.padding-floor{
    padding: 5px 16px !important;
}