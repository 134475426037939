.announcement-type-buttons {
    background-color: var(--ui-line-break);
    border: 2px solid var(--ui-background-alternate);
    border-radius: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.announcement-button-selected {
    border: 2px solid var(--ui-primary);
    box-shadow: 0px 4px 4px 0px #00000040;
}

.announcement-button-content {
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.announcement-button-logo-container {
    position: relative;
    padding: 10px 20px;
    cursor: pointer;
    align-items: center;
    display: flex;
}

.field-heading {
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5;
}

.field-message {
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.5;
}