.bookingTimeCard {
    display: flex;
    border-radius: 8px;
    background-color: var(--ui-line-break);
    height: 100px;
    width: 49%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.TimeCardText {
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
}

.TimeCardHeader {
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.5;
}

.setupResetTimeCard {
    text-align: center;
    align-self: center;
    justify-content: center;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border-radius: 8px;
    border: 1px solid #DFE2E7;
    width: 90%;
}