.loadingIndicator {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loadingOverlay {
    .loadingOverlay__background{
        position: absolute;
        z-index: 1100;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #000;
        opacity: 0.5;
    }
    
    .text-center {
        z-index: 1100;
        position: absolute;
        display: block;
        top: calc(50% - (58px / 2));
        right: calc(50% - (58px / 2));
        opacity: 1;
    }
}