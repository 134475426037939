.schedule-dialog-row
{
    /* display: 'flex', alignItems: 'start', gap: '0.5em', mb: '0.5em */
    display: flex;
    align-items: start;
    gap: 0.5em;
    margin-bottom: 0.5em;
}

p.schedule-dialog-info
{
    /*fontSize: '1.25em', fontFamily: 'Source Sans Pro', fontWeight: 'bold' */
    font-size: 1.25em; 
    font-family: Source Sans Pro; 
    font-weight: bold;
}

p
{
    /*fontSize: '1.25em', fontFamily: 'Source Sans Pro', fontWeight: 'bold' */
    font-size: 1.25em; 
    font-family: Source Sans Pro; 
    font-weight: bold;
}