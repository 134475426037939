// Your custom styles
.l-h-24 {
  line-height: 24px;
}

.visitor-badge {
  img {
    width: 120px;
    height: 40px;
  }

  .name {
    font-size: 26px;
    padding-bottom: 5px;
    font-weight: bold;
  }

  .time {
    font-size: 20px;
    padding-bottom: 15px;
    font-weight: bold;
  }
}

#visitorBadgeContent {
  display: none;
}

//IBSS pdf print file
.ibssheader{
  background-color: #26373e;
  color: #fff;
  margin-bottom: 5px;
  height: 60px;
}
.visitorheader {
  margin-left: -30px;
}
.ibsslogo {
  width: 120px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.namepdf {
  font-size: 26px;
  padding-bottom: 1px;
  font-weight: bold;
}

.visitordetailsalign{
  margin-left: 30px;
}

//EBRD pdf print file
.ebrdheader {
  background-color: #2e2a69;
  color: #fff;
  margin-bottom: 5px;
  height: 60px;
}

.ebrdlogo{
  max-width: 180px !important;
  height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.-sort-desc {
  &:before {
    float: left;
    margin-right: 10px;
  }
  cursor: pointer;
}

.-sort-asc {
  &:before {
    float: left;
    margin-right: 10px;
  }
  cursor: pointer;
}
.sorthover {
  &:before {
    color: #EFEFEF;
    float: left;
    margin-right: 10px;
  }
  cursor: pointer;
}