.tag-button{
    background-color:var(--ui-light-text);
    color:var(--ui-text);
    font-size: 1.125rem;
    font-weight: 600;
    padding:2.5rem;
    border-radius:0.5rem;
    cursor:pointer;
    margin-bottom:1.5rem;
}
.active-tag-btn{
    background-color:var(--ui-text);
    color:var(--ui-light-text);
}
.add-icon{
    color:var(--ui-primary);
    font-size: 1.5rem;
    padding:0.4rem 1rem;
    background-color:var(--ui-light-pastel-blue);
    border-radius:0.25rem;
}
.option-box{
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 1px solid var(--ui-line-break);
}