.recurring-space-card {
    height: auto;
    border-radius: 5px;
    border: 1px solid var(--ui-line-break);
}

.card-selected {
    border: 3px solid var(--ui-primary-light);
    box-shadow: 0px 4px 4px 0px #00000040;
}

.content-card-desk {
    background-color: var(--ui-line-break);
    border-radius: 0px;
}

.space-info-desk {
    border: 1px solid var(--ui-line-break);
    padding-bottom: 10px;
}

.content-card-single {
    background-color: var(--ui-background-alternate);
    border-radius: 0px;
}

.space-info-single {
    border: 1px solid var(--ui-background-alternate);
    padding-bottom: 10px;
}

.additional-info {
    background-color: var(--ui-line-break);
}


.headings {
    font-family: Source Sans Pro;
    font-size: 0.75rem!important;
    letter-spacing: 0em;
}

.availability-text {
    font-size: 0.813rem;
    line-height: 13px;
    letter-spacing: 0em;
    text-align: left;
    align-self: center;
}

.occurences {
    font-family: Source Sans Pro;
    font-size: 0.875rem;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.card-image {
    width: 100%;
    object-fit: cover;
    height: 133px;
    z-index: 2;
}

.recurring-icon {
    img {
        filter: brightness(3) invert(1);
        height: 18px;
        width: 18px;
        padding: 0 12px 0 5px;
    }
}

.best-fit-label {
    position: absolute;
    background-color: var(--ui-primary-light );
    border-radius: 16px;
    padding: 5px 20px;
    margin: 15px 15px;
    color: var(--ui-background-alternate);
    font-family: 'Source Sans Pro';
    font-size: 1rem;
    font-weight: 400;
}