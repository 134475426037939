
.markdown-editor
{
    display: flex;
    flex-direction: column;
    min-height: 240px; /* needed to accomodate popout menus */
}

.markdown-editor__toolbar.MuiToolbar-root
{
    flex-shrink: 0;
    flex-wrap: wrap;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-bottom: none;
    min-height: 0;
    padding: 3px 0 0 3px;
}

.markdown-editor__toolbar-button.MuiButton-root
{
    width: 24px;
    height: 24px;
    color: var(--ui-text);
    min-width: 0;
    margin: 0 3px 3px 0;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
}

    .markdown-editor__toolbar-button.MuiButton-root:hover
    {
        background-color: var(--ui-background);
    }

.markdown-editor__editor
{
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    font-weight: normal;
}

    .markdown-editor__editor:hover
    {
        outline: none;
        border: 1px solid rgba(0, 0, 0, 1);
    }

    .markdown-editor__editor:focus
    {
        outline: none;
        border: 1px solid var(--ui-primary-light);
    }

    .markdown-editor__editor p
    {
        margin: 0 0 10px 0;
        font-weight: normal;
        font-size: 1em;
    }

    .markdown-editor__editor a
    {
        text-decoration: underline;
        color: var(--ui-primary);
    }

        .markdown-editor__editor a:hover
        {
            text-decoration: underline;
            color: var(--ui-primary);
        }

        .markdown-editor__editor a:active
        {
            text-decoration: underline;
            color: var(--ui-primary);
        }

    .markdown-editor__editor ol,
    .markdown-editor__editor ul
    {
        padding-left: 20px;
    }

.markdown-editor__code-block
{
    display: block;
    border: solid var(--scroll-track) 1px;
    border-left: solid var(--scroll-track) 5px;
    padding: 2px;
    margin: 5px 0;
    overflow-x: auto;
    white-space: pre;
}

    .markdown-editor__code-block p
    {
        margin: 0;
        font-family: 'courier new';
        font-size: 0.9em;
    }

    .markdown-editor__code-block::-webkit-scrollbar
    {
        height: 8px;
    }

    .markdown-editor__code-block::-webkit-scrollbar-thumb
    {
        background-color: rgba(0, 0, 0, 0.2);
        cursor: default;
    }

        .markdown-editor__code-block::-webkit-scrollbar-thumb:hover
        {
            background-color: rgba(0, 0, 0, 0.3);
        }

    .markdown-editor__code-block::-webkit-scrollbar-track
    {
        background: rgba(0, 0, 0, 0.05);
    }

.markdown-editor__thematic-break
{
    background: var(--scroll-track);
    height: 2px;
    margin: 5px 0;
    line-height: 0.1;
}

.markdown-editor__overlay
{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: auto;
}

.markdown-editor__dialog
{
    position: absolute;
    top: 24px;
    left: 0;
    border: solid rgba(0, 0, 0, 0.23) 1px;
    background-color: var(--ui-background-alternate);
    z-index: 1;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
    cursor: auto;
    padding: 10px;
}

    .markdown-editor__dialog.markdown-editor__dialog--heading
    {
        padding: 10px 0;
    }

        .markdown-editor__dialog.markdown-editor__dialog--heading ul
        {
            list-style-type: none;
            padding: 0;
            margin: 0;
        }

        .markdown-editor__dialog.markdown-editor__dialog--heading li
        {
            padding: 0;
            margin: 0;
            text-align: left;
            cursor: pointer;
        }

            .markdown-editor__dialog.markdown-editor__dialog--heading li:hover
            {
                background-color: var(--ui-background);
            }

            .markdown-editor__dialog.markdown-editor__dialog--heading li *
            {
                margin: 0 10px;
                white-space: nowrap;
                text-transform: none;
            }

    .markdown-editor__dialog.markdown-editor__dialog--link label
    {
        font-size: 0.8rem;
    }

        .markdown-editor__dialog.markdown-editor__dialog--link label.Mui-focused
        {
            font-size: 1rem;
        }

        .markdown-editor__dialog.markdown-editor__dialog--link label.MuiFormLabel-filled
        {
            font-size: 1rem;
        }

    .markdown-editor__dialog.markdown-editor__dialog--link input
    {
        width: 280px;
        font-size: 0.8rem;
        padding: 8px 10px 5px 10px;
    }

    .markdown-editor__dialog.markdown-editor__dialog--link .Mui-error
    {
        text-transform: none;
        margin: 0px 0 0 3px;
    }

    .markdown-editor__dialog.markdown-editor__dialog--link button
    {
        height: 22px;
    }
