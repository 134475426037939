.heading {
    font-size: 1.25rem;
    margin-right: 8px;
}

.subText {
    color: var(--ui-text-alternate);
    font-size: 0.875rem;
}

.behaviours-radioBtns {
    .MuiFormGroup-row {
        column-gap: 250px;
    }

    @media (min-width: 1536px) {
        .MuiFormGroup-row {
            column-gap: 135px;
        }
    }

    @media (min-width: 1720px) {
        .MuiFormGroup-row {
            column-gap: 200px;
        }
    }
}

.behaviours-time-input {
    column-gap: 190px;

    @media (min-width: 1536px) {
        column-gap: 90px;
    }

    @media (min-width: 1720px) {
        column-gap: 145px;
    }
}
.view-booking-policies-data-grid{
    .MuiDataGrid-cellContent{
        cursor: pointer;
    }
    .MuiDataGrid-cell:focus{
        outline: none !important;
    }
}