.spaceDetailsSubTitle {
    font-family: Source Sans Pro;
    font-size: 1rem;
}

.spaceDetailsTitle {
    font-family: Source Sans Pro;
    font-size: 1.25rem;
}

.placeholderImage {
    width: 100px;
    height: 100px;
    background-color: var(--ui-mid-tone) ;
}

.spaceGuideOptionHover:hover {
    background-color: #80808026;
}