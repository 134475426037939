.bookings-dashboard__drawer
{
    right: 0px; 
    top: 60px!important; 
    min-Width: 500px;  
    max-Width: 600px; 
    width: 600px!important; 
    overflow: auto; 
    background-color: var(--ui-background-alternate)!important;
}

.bookingDashboard-drawer-editBooking-btn
{
    font-family: 'Source Sans Pro';
    font-weight: 700;
    color: var(--ui-text);
    width: 200px;
}

.bookingDashboard-drawer-checkin-btn
{
    font-family: 'Source Sans Pro';
    font-weight: 700;
    width: 200px;
}

.bookingDashboard-bookingParticipant-container
{
    display: flex;
    justify-content: space-between; 
    align-items: center; 
    width: 100%; 
    gap: 0.5em;
}

.bookingDashBoard-visitors-container
{
    display: flex; 
    justify-content: space-between;
    padding-right: '1em';
    gap: 1em;
}