.schedule-calender{
    max-height:calc(100vh - 196px);
    overflow:auto;
  }
  .e-resource-text{
    border: 1px solid var(--ui-line-break)!important;
    border-radius: 10px!important;
    padding: 16px!important;
    margin: 0px 10px!important;
  }
  .e-resource-text:focus{
    background-color:var(--ui-background)!important;
  }
  .e-schedule .e-timeline-view .e-resource-cells:focus{
    background-color:var(--ui-background-alternate)!important;
  }
  .e-resource-left-td>.e-resource-text{
    border:none!important;
  }